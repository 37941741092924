@font-face {
    font-family: 'input-mono-regular';
    src: url('../../public/fonts/input-mono-regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'input-mono-medium';
    src: url('../../public/fonts/input-mono-semibold.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'input-mono-bold';
    src: url('../../public/fonts/input-mono-bold.ttf') format('truetype');
    font-weight: 700;
}

/******* animation ********/
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 300s linear infinite;
    -moz-animation: rotating 300s linear infinite;
    -ms-animation: rotating 300s linear infinite;
    -o-animation: rotating 300s linear infinite;
    animation: rotating 300s linear infinite;
}
/* Stop animation */

/* LOADER CSS */
.placeholder {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.placeholder-content_item {
    height: 16px;
    width: 32px;
    z-index: 2;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-name: placeholderAnimate;
    background: linear-gradient(
        to right,
        rgba(178, 178, 178, 0) 0%,
        rgba(178, 178, 178, 0) 20%,
        rgba(178, 178, 178, 0.8) 100%
    );
    background-size: 1300px;
}

@keyframes placeholderAnimate {
    0% {
        background-position: 0px 0;
    }
    100% {
        background-position: 650px 0;
    }
}

.placeholder-content_item:nth-child(1) {
    width: 50px;
    animation-delay: 0s;
}
.placeholder-content_item:nth-child(2) {
    width: 15px;
    animation-delay: 0.05s;
}
.placeholder-content_item:nth-child(3) {
    width: 14px;
    animation-delay: 0.1s;
}
.placeholder-content_item:nth-child(4) {
    width: 53px;
    animation-delay: 0.15000000000000002s;
}
.placeholder-content_item:nth-child(5) {
    width: 59px;
    animation-delay: 0.2s;
}
.placeholder-content_item:nth-child(6) {
    width: 23px;
    animation-delay: 0.25s;
}
.placeholder-content_item:nth-child(7) {
    width: 38px;
    animation-delay: 0.30000000000000004s;
}
.placeholder-content_item:nth-child(8) {
    width: 41px;
    animation-delay: 0.35000000000000003s;
}
.placeholder-content_item:nth-child(9) {
    width: 43px;
    animation-delay: 0.4s;
}
.placeholder-content_item:nth-child(10) {
    width: 46px;
    animation-delay: 0.45s;
}
.placeholder-content_item:nth-child(11) {
    width: 31px;
    animation-delay: 0.5s;
}
.placeholder-content_item:nth-child(12) {
    width: 52px;
    animation-delay: 0.55s;
}
.placeholder-content_item:nth-child(13) {
    width: 22px;
    animation-delay: 0.6000000000000001s;
}
.placeholder-content_item:nth-child(14) {
    width: 20px;
    animation-delay: 0.65s;
}
.placeholder-content_item:nth-child(15) {
    width: 57px;
    animation-delay: 0.7000000000000001s;
}
.placeholder-content_item:nth-child(16) {
    width: 43px;
    animation-delay: 0.75s;
}
.placeholder-content_item:nth-child(17) {
    width: 28px;
    animation-delay: 0.8s;
}
.placeholder-content_item:nth-child(18) {
    width: 43px;
    animation-delay: 0.8500000000000001s;
}
.placeholder-content_item:nth-child(19) {
    width: 21px;
    animation-delay: 0.9s;
}
.placeholder-content_item:nth-child(20) {
    width: 47px;
    animation-delay: 0.9500000000000001s;
}
.placeholder-content_item:nth-child(21) {
    width: 52px;
    animation-delay: 1s;
}
.placeholder-content_item:nth-child(22) {
    width: 20px;
    animation-delay: 1.05s;
}
.placeholder-content_item:nth-child(23) {
    width: 19px;
    animation-delay: 1.1s;
}
.placeholder-content_item:nth-child(24) {
    width: 44px;
    animation-delay: 1.1500000000000001s;
}
.placeholder-content_item:nth-child(25) {
    width: 53px;
    animation-delay: 1.2000000000000002s;
}
.placeholder-content_item:nth-child(26) {
    width: 44px;
    animation-delay: 1.25s;
}
.placeholder-content_item:nth-child(27) {
    width: 35px;
    animation-delay: 1.3s;
}
.placeholder-content_item:nth-child(28) {
    width: 37px;
    animation-delay: 1.35s;
}
.placeholder-content_item:nth-child(29) {
    width: 50px;
    animation-delay: 1.4000000000000001s;
}
.placeholder-content_item:nth-child(30) {
    width: 46px;
    animation-delay: 1.4500000000000002s;
}
.placeholder-content_item:nth-child(31) {
    width: 14px;
    animation-delay: 1.5s;
}
.placeholder-content_item:nth-child(32) {
    width: 53px;
    animation-delay: 1.55s;
}
.placeholder-content_item:nth-child(33) {
    width: 44px;
    animation-delay: 1.6s;
}
.placeholder-content_item:nth-child(34) {
    width: 55px;
    animation-delay: 1.6500000000000001s;
}
.placeholder-content_item:nth-child(35) {
    width: 42px;
    animation-delay: 1.7000000000000002s;
}
.placeholder-content_item:nth-child(36) {
    width: 51px;
    animation-delay: 1.75s;
}
.placeholder-content_item:nth-child(37) {
    width: 50px;
    animation-delay: 1.8s;
}
.placeholder-content_item:nth-child(38) {
    width: 37px;
    animation-delay: 1.85s;
}
.placeholder-content_item:nth-child(39) {
    width: 47px;
    animation-delay: 1.9000000000000001s;
}
.placeholder-content_item:nth-child(40) {
    width: 18px;
    animation-delay: 1.9500000000000002s;
}
.placeholder-content_item:nth-child(41) {
    width: 45px;
    animation-delay: 2s;
}
.placeholder-content_item:nth-child(42) {
    width: 34px;
    animation-delay: 2.0500000000000003s;
}
.placeholder-content_item:nth-child(43) {
    width: 45px;
    animation-delay: 2.1s;
}
.placeholder-content_item:nth-child(44) {
    width: 24px;
    animation-delay: 2.15s;
}
.placeholder-content_item:nth-child(45) {
    width: 13px;
    animation-delay: 2.2s;
}
.placeholder-content_item:nth-child(46) {
    width: 35px;
    animation-delay: 2.25s;
}
.placeholder-content_item:nth-child(47) {
    width: 48px;
    animation-delay: 2.3000000000000003s;
}
.placeholder-content_item:nth-child(48) {
    width: 39px;
    animation-delay: 2.35s;
}
.placeholder-content_item:nth-child(49) {
    width: 23px;
    animation-delay: 2.4000000000000004s;
}
.placeholder-content_item:nth-child(50) {
    width: 16px;
    animation-delay: 2.45s;
}
.placeholder-content_item:nth-child(51) {
    width: 46px;
    animation-delay: 2.5s;
}
.placeholder-content_item:nth-child(52) {
    width: 55px;
    animation-delay: 2.5500000000000003s;
}
.placeholder-content_item:nth-child(53) {
    width: 31px;
    animation-delay: 2.6s;
}
.placeholder-content_item:nth-child(54) {
    width: 45px;
    animation-delay: 2.6500000000000004s;
}
.placeholder-content_item:nth-child(55) {
    width: 59px;
    animation-delay: 2.7s;
}
.placeholder-content_item:nth-child(56) {
    width: 59px;
    animation-delay: 2.75s;
}
.placeholder-content_item:nth-child(57) {
    width: 15px;
    animation-delay: 2.8000000000000003s;
}
.placeholder-content_item:nth-child(58) {
    width: 13px;
    animation-delay: 2.85s;
}
.placeholder-content_item:nth-child(59) {
    width: 12px;
    animation-delay: 2.9000000000000004s;
}
.placeholder-content_item:nth-child(60) {
    width: 42px;
    animation-delay: 2.95s;
}
.placeholder-content_item:nth-child(61) {
    width: 47px;
    animation-delay: 3s;
}
.placeholder-content_item:nth-child(62) {
    width: 59px;
    animation-delay: 3.0500000000000003s;
}
.placeholder-content_item:nth-child(63) {
    width: 40px;
    animation-delay: 3.1s;
}
.placeholder-content_item:nth-child(64) {
    width: 25px;
    animation-delay: 3.1500000000000004s;
}
.placeholder-content_item:nth-child(65) {
    width: 23px;
    animation-delay: 3.2s;
}
.placeholder-content_item:nth-child(66) {
    width: 55px;
    animation-delay: 3.25s;
}
.placeholder-content_item:nth-child(67) {
    width: 46px;
    animation-delay: 3.3000000000000003s;
}
.placeholder-content_item:nth-child(68) {
    width: 13px;
    animation-delay: 3.35s;
}
.placeholder-content_item:nth-child(69) {
    width: 42px;
    animation-delay: 3.4000000000000004s;
}
.placeholder-content_item:nth-child(70) {
    width: 51px;
    animation-delay: 3.45s;
}
.placeholder-content_item:nth-child(71) {
    width: 26px;
    animation-delay: 3.5s;
}
.placeholder-content_item:nth-child(72) {
    width: 49px;
    animation-delay: 3.5500000000000003s;
}
.placeholder-content_item:nth-child(73) {
    width: 58px;
    animation-delay: 3.6s;
}
.placeholder-content_item:nth-child(74) {
    width: 44px;
    animation-delay: 3.6500000000000004s;
}
.placeholder-content_item:nth-child(75) {
    width: 27px;
    animation-delay: 3.7s;
}
.placeholder-content_item:nth-child(76) {
    width: 48px;
    animation-delay: 3.75s;
}
.placeholder-content_item:nth-child(77) {
    width: 19px;
    animation-delay: 3.8000000000000003s;
}
.placeholder-content_item:nth-child(78) {
    width: 54px;
    animation-delay: 3.85s;
}
.placeholder-content_item:nth-child(79) {
    width: 39px;
    animation-delay: 3.9000000000000004s;
}
.placeholder-content_item:nth-child(80) {
    width: 59px;
    animation-delay: 3.95s;
}
.placeholder-content_item:nth-child(81) {
    width: 17px;
    animation-delay: 4s;
}
.placeholder-content_item:nth-child(82) {
    width: 30px;
    animation-delay: 4.05s;
}
.placeholder-content_item:nth-child(83) {
    width: 41px;
    animation-delay: 4.1000000000000005s;
}
.placeholder-content_item:nth-child(84) {
    width: 48px;
    animation-delay: 4.15s;
}
.placeholder-content_item:nth-child(85) {
    width: 41px;
    animation-delay: 4.2s;
}
.placeholder-content_item:nth-child(86) {
    width: 54px;
    animation-delay: 4.25s;
}
.placeholder-content_item:nth-child(87) {
    width: 17px;
    animation-delay: 4.3s;
}
.placeholder-content_item:nth-child(88) {
    width: 19px;
    animation-delay: 4.3500000000000005s;
}
.placeholder-content_item:nth-child(89) {
    width: 23px;
    animation-delay: 4.4s;
}
.placeholder-content_item:nth-child(90) {
    width: 33px;
    animation-delay: 4.45s;
}
.placeholder-content_item:nth-child(91) {
    width: 40px;
    animation-delay: 4.5s;
}
.placeholder-content_item:nth-child(92) {
    width: 35px;
    animation-delay: 4.55s;
}
.placeholder-content_item:nth-child(93) {
    width: 48px;
    animation-delay: 4.6000000000000005s;
}
.placeholder-content_item:nth-child(94) {
    width: 36px;
    animation-delay: 4.65s;
}
.placeholder-content_item:nth-child(95) {
    width: 27px;
    animation-delay: 4.7s;
}
.placeholder-content_item:nth-child(96) {
    width: 47px;
    animation-delay: 4.75s;
}
.placeholder-content_item:nth-child(97) {
    width: 53px;
    animation-delay: 4.800000000000001s;
}
.placeholder-content_item:nth-child(98) {
    width: 50px;
    animation-delay: 4.8500000000000005s;
}
.placeholder-content_item:nth-child(99) {
    width: 34px;
    animation-delay: 4.9s;
}
.placeholder-content_item:nth-child(100) {
    width: 19px;
    animation-delay: 4.95s;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

/* Define the keyframes for the cursor animation */
@keyframes flicker {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.cursor {
    margin-left: 2px;
    font-weight: 800;
    display: inline-block;
    width: 0.4ch;
    animation: flicker 0.5s infinite;
    /* margin-bottom: 4px; */
    /* background-color: 'white'; */
}
